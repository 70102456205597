import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Instagram from 'assets/footer/instagram.svg';
import LinkedIn from 'assets/footer/linkedin.svg';
import Logo from 'assets/footer/logo.svg';
import TikTok from 'assets/footer/tiktok.svg';
import Twitter from 'assets/footer/twitter.svg';
import Icon from 'components/Icon';
import Text from 'components/Text';

import * as Styled from './styles';

const menuItems = [
  ['Brands', 'https://paritynow.co/brands/', true],
  ['Athletes', 'https://paritynow.co/athletes/', true],
  ['NFTs', 'https://www.market.paritynow.co/', true],
  ['Parity Fans', 'https://paritynow.co/parity-fans/', true],
  ['About Us', 'https://paritynow.co/about-us/', true],
  ['Impact', 'https://impact.paritynow.co/', true],
];

const socialLinks = [
  ['Instagram', 'https://www.instagram.com/paritynow/', Instagram],
  ['LinkedIn', 'https://www.linkedin.com/company/paritynow/', LinkedIn],
  ['Twitter', 'https://twitter.com/paritynow___', Twitter],
  ['TikTok', 'https://twitter.com/paritynow___', TikTok],
];

const Footer = () => (
  <Styled.Footer>
    <Styled.Layout>
      <Styled.MobileLogoWrapper>
        <Styled.MobileLogo>
          <Image alt="Parity" src={Logo} layout="fill" />
        </Styled.MobileLogo>
      </Styled.MobileLogoWrapper>
      <Styled.List>
        {menuItems.map((item, index) =>
          item[2] ? (
            <li key={`li-${index}`}>
              <a href={item[1]} target="_blank" rel="noopener noreferrer" aria-label={`Read more about ${item[0]}`}>
                {item[0]}
              </a>
              <Styled.Divider />
            </li>
          ) : (
            <li key={`li-${index}`}>
              <Link href={item[1] as string}>{item[0]}</Link>
              <Styled.Divider />
            </li>
          )
        )}
        <li>
          <a
            tabIndex={0}
            role="button"
            onClick={(e) => {
              e.preventDefault();
              // @ts-expect-error
              if (window.displayPreferenceModal) {
                // @ts-expect-error
                window.displayPreferenceModal();
              }
            }}
          >
            Manage Cookie Preferences
          </a>
        </li>
      </Styled.List>
      <Styled.ExtraLinks>
        <Styled.DesktopLogo>
          <Image alt="Parity" src={Logo} layout="fill" />
        </Styled.DesktopLogo>
        <Styled.ExtraCenter>
          <Text>{new Date().getFullYear()} © Parity powered by EOS, Inc.</Text>
          <Styled.ExtraDivider />
          <Link href="/privacy-policy">Privacy Policy</Link>
          <Styled.ExtraDivider />
          <Link href="/cookie-policy">Cookie Policy</Link>
        </Styled.ExtraCenter>
        <Styled.SocialsLinks>
          {socialLinks.map((item, index) => (
            <li key={`li-${index}`}>
              <a href={item[1]} target="_blank" rel="noreferrer noopener" aria-label={`Read more on ${item[0]}`}>
                <Icon alt={item[0]} src={item[2]} size={24} />
              </a>
            </li>
          ))}
        </Styled.SocialsLinks>
      </Styled.ExtraLinks>
    </Styled.Layout>
  </Styled.Footer>
);

export default React.memo(Footer);
